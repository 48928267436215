.aa-Item {
  &:hover {
    background-color: rgba(218, 223, 225);
  }
  // max-width: 270px !important;
  padding: 4px !important;
}

.aa-Panel {
  // max-width: 650px;
}

@media (min-width: 700px) {
  .aa-Panel {
    max-width: 100%;
  }
}

.no-result {
  padding: 20px 25px;

  &--title {
    color: #808080;
  }

  &--see-all {
    margin-top: 10px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      text-decoration-color: black;
    }
  }
}

.autocomplete-footer {
  padding: 20px 20px;
  background-color: #f6f6f6;
  

  &__stats {
    cursor: pointer;
    font-weight: normal !important;
    
    &:hover {
      color: black;
      text-decoration: underline;
    }
  }
  &__poweredby {
    display: flex;
    justify-content: right;
    margin-top: 10px;
    float: 'right' !important;
  }
}

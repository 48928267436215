.search-button-osg{
    padding: 0px !important;
    border-radius: 0 0 0 0 !important;
}

.search-button-icon svg{
    position: unset !important;
    color: #ffffff;
}

.search-button{
    border-radius: 0px 3px 3px 0px;
    background: #00559D;
}

.simplebar-content-wrapper{
    overflow: hidden scroll !important;
}

.unified-search-header__close-button svg, .ais-ClearRefinements-button svg, .ais-Pagination-link svg{
    left: unset !important;
    height: 1em !important;
    top: unset !important;
    width: 0.6875em !important;
    position: unset !important;
}

#o_search_custom{
    margin-bottom: 0px !important;
}

#o_search_custom fieldset div{
    margin-bottom: 0px !important;
}

.custom-ItemContentBody{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.aa-customItemContent{
    grid-template-columns: 100px 400px 120px;
}

.custom-viewDetails{
    background: #F0F0F0;
    border-radius: 5px;
    text-decoration: none;
    padding: 9px 13px;
    color: #4E4E4E;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
}

.custom-searchHeader{
    color: #00559D;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.aa-ListResultCustom{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #000000;
    list-style-type: none;
}

.aa-ListCustomCategories{
    display: flex;
    flex-wrap: wrap;
}

.aa-LinkCustomReference:not(:last-child):after{
    content: " \203A  ";
}

.aa-LinkReference{
    text-decoration: none;
}

.aa-PanelLayout-custom{
    background: rgb(244, 250, 255);
    padding: 0px !important;
}

.aa-OtherResults{
    display: flex;
    flex-direction: row; 
    gap: 1rem;
}

.aa-osgCustomPanel {
    max-width: auto;
    width: 1000px !important;
    float: left;
}

.aa-OtherResultsPanel{
    padding: 0px 20px 0px 20px;
    width: 30%;
}

.aa-customList{
    gap: 0.5rem;
    padding: 20px;
    background: #ffffff;
    width: 70% !important;
}

.aa-Form-custom{
    border-color: #d9d9d9;
    padding: 0.75rem 0rem 0.75rem 1rem;
    height: 3.2rem;
}

@media screen and (min-width:784px) and (max-width:1000px){
    .aa-customItemContent {
        grid-template-columns: 100px 250px 120px;
    }

    .aa-osgCustomPanel{
        width: calc(75%) !important;
    }
}

@media screen and (min-width:100px) and (max-width:785px){
    .aa-customItemContent {
        grid-template-columns: 100px 400px 120px;
    }

    .aa-osgCustomPanel{
        width: calc(90%) !important;
    }

    .aa-OtherResults{
        flex-direction: column !important; 
    }

    .aa-OtherResultsPanel{
        width: 100% !important;
    }

    .aa-customList{
        width: 100% !important;
    }

}

.unified-hits__inner__image-container-custom {
    height: 150px !important;
}

.unified-hits__inner__image-container-custom img {
    height: auto !important;
    min-height: 0px !important;
    max-height: 150px !important;
}
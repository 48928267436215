$themeColor: var(--my-custom-themecolor);
$headerColor: var(--my-custom-headercolor);

.unified-search-header-container{
  //overflow-y: scroll !important;
 // margin: 3rem 5rem;
  margin: 0;
  @media only screen and (max-width: 1000px){
    margin: 1rem;
  }
}

#customScrollBar{
  height: 85%;
  @media only screen and (max-width: 1000px){
    height: 75%;
  }
}

.unified-search-header {
  background-color: $headerColor;
  width: 100%;
  height: 136px;
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #cfcfcf;


  &__searchbox {
    width: 40%;
    box-shadow: 0 4px 48px 0 rgb(0 0 0 / 20%);
    height: 60px;
    -webkit-border-radius: 50px;
    -khtml-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
 
    .ais-SearchBox-form::before{
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 22px;
      bottom: 0;
      left: 8px;
    }
    form{
      height: 60px;
      -webkit-border-radius: 50px;
      -khtml-border-radius: 50px;
      -moz-border-radius: 50px;
      border-radius: 50px;
      
      svg{
          width: 20px;
          height: 20px;
      }
    }
    input{
      height: 60px;
      font-size: 20px;
      padding-left: 3rem;
      -webkit-border-radius: 20px;
      -khtml-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      box-sizing: border-box !important;
    }
    @media only screen and (max-width: 1000px){
      width: 90%;
    }
  }

  &__close-button {
    align-items: center;
    //background: white;
    border: solid 1px #ccc;
    background-color: #cecece;
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    height: 50px;
    width: 50px;
    margin: 10px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    @media only screen and (max-width: 1000px){
      width: 30px;
      height: 30px;
      svg {
          font-size: 16px;
        }
    }
    &:hover,
    &:focus {
      color: rgb(68, 68, 68);
      svg {
          color: $themeColor;
        }
    }
    svg{
      font-size: 20px;
      color: rgb(68, 68, 68);
      // @media only screen and (max-width: 1000px){
      //   font-size: 20px;
      // }
    }
  }
}

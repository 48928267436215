$themeColor: var(--my-custom-themecolor);
$productDescription: var(--my-custom-productDescription);
$displayPrices: var(--my-custom-displayPrices);

.uni-BodyHeader-stats, 
.unified-refinements__content__header--title
{
  font-size: 25px;
}
.ais-ClearRefinements-button{
  font-size: 15px !important;;
}
.unified-hits {
  color: #21243d;
  font-size: 14px;
  line-height: 18px;
  height: 100%;
  cursor: pointer;
  border: 1px solid #ddd;

  &__inner__body{
    @media only screen and (max-width: 1000px){
      text-align: center;
    }
  }
  
  &__inner {
    border: 1px solid #fff;
    border-radius: 3px;
    color: inherit;
    display: block;
    padding: 20px;
    position: relative;
    text-decoration: inherit;
    height: inherit;
    transition: ease 0.4s;
    .ais-Highlight-highlighted,.ais-Snippet-highlighted{
      color: $themeColor !important;
    }
    &:hover,
    &:focus {
      // border-color: $themeColor;
      box-shadow: 0 3px 8px $themeColor;
      background-color: rgba(100, 100, 100, 0.1);
      // .uni-Hit-title {
      //   text-decoration: underline;
      // }
      .ais-Highlight-highlighted,
      .ais-Snippet-highlighted {
        border-bottom: 0;
      }
    }

    &__image-container {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 20px;
      height: 50%;
      min-height: 50%;
      max-height: 50%;
      img {
        flex-shrink: 0;
        height: 160px;
        min-height: 160px;
        max-height: 160px;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      overflow-wrap: break-word;
      word-break: break-word;
      height: 40%;
      min-height: 40%;
      max-height: 40%;
      &__header {
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
        &--category {
          color: #21243d;
          font-size: 12px;
          font-weight: 600;
          line-height: 1;
          margin-bottom: 5px;
          opacity: 0.7;
          text-transform: uppercase;
        }

        &--title {
          font-size: 14px !important;
          font-weight: 600;
          letter-spacing: 1px;
        }
      }

      &__description {
        display: $productDescription !important;
        color: #777;
       margin-bottom: 5px;
      }

      &--ellips {
        overflow: hidden;
        text-overflow:ellipsis;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
      }

      footer{
        text-align: center;
      }
      &--currency {
        display: $displayPrices;
        color: var(--algolia-theme-color-primary);
        font-size: 13px;
        font-weight: 600;
        padding-right: 2px;
      }

      &--price {
        font-weight: 600;
        display: $displayPrices;
      }
    }
  }
}

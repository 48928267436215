.unified-custom-hits {
  display: grid;
  grid-template-columns: repeat(auto-fill, 320px);
  grid-gap: 10px;
  //overflow-x: hidden;
  padding-top: 30px;
  border-top: 1px solid #ebecf3;
  list-style-type: none;
  justify-content: center;
  li{
    height: auto;
    // height: 350px;
    &:hover{
      span{
        color: black !important;
      }
    }
  }
  
  @media only screen and (max-width: 1000px){
    display: block;
    li{
        margin: 1rem 0;
    }
  }
}
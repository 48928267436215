.findDist-header {
    color: rgba(0,72,133,1);
    font-weight: 700;
    font-size: clamp(1.4rem, 2.5vw, 1.6rem);
    margin: 20px auto;
	text-align: center;
}
.findDist-container {
	height: 100vh;
	position: relative;
	overflow: hidden;
    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
        padding: 0;
    }
	svg {
        position: static;
		color: #00559d;
		font-size: 15px;
	}
}
.swal2-confirm {
	width: 100px;
	background-color: #0071e3 !important;
}
.findDist-modal {
	width: 100%;
	max-width: 500px;
	height: 100vh;
	position: absolute;
	left: 0;
	top: 0;
	padding: 50px 20px;
	z-index: 100;
	overflow: scroll;
}
.findDist-search {
	display: flex;
	flex-direction: column;
	gap: 10px;
	background-color: #ffffff;
	padding: 20px;
	border-radius: 10px;
	h2 {
		font-size: 20px;
		font-weight: 700;
	}
}
.findDist-searchField {
	display: flex;
	flex-direction: row;
	gap: 10px;
    .ais-SearchBox {
        width: 100% !important;
    }
    .ais-SearchBox-input {
        padding-right: 2rem !important;
        padding-left: 1rem !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        border: 1px solid #afafaf !important;
    }
    .ais-SearchBox-form::before {
        left: initial !important;
        right: 1rem !important;
    }
    .ais-SearchBox-reset {
        display: none !important;
    }
	.findDist-loc {
        width: 40px;
		display: flex;
		align-items: center;
		background-color: rgb(255, 255, 255);
		border: none;
		padding: 0 10px;
		font-size: 21px;
		cursor: pointer;
		border: 1px solid #afafaf;
		border-radius: 3px;

		svg {
			color: #6e6e6e !important;
		}
	}
}
// .findDist-searchCont {
// 	display: flex;
// 	flex-direction: row;
// }
.findDist-radius {
	width: 100%;
	border: 1px solid #afafaf;
	border-radius: 3px;
	padding: 10px;
}
.findDist-info {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px;
	gap: 10px;
	width: 100%;
	flex: none;
	align-self: stretch;
	flex-grow: 0;
	border-top: 1px solid #afafaf;
	border-bottom: 1px solid #afafaf;
	// margin: 10px 0;
	padding: 10px 0;
	span {
		font-weight: 700;
		font-size: 13px;
		line-height: 24px;
		color: #111827;
	}
	.left-half {
		width: 100%;
		align-items: center;
		text-align: left;
	}

	.right-half {
		width: 100%;
		align-items: center;
		text-align: right;
	}

	.right-half a {
		color: #00559d;
		font-weight: 700;
		font-size: 14px;
		text-decoration: none;
		border-bottom: 1px solid #00559d;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	ul li {
		display: flex;
		align-items: center;
		gap: 5px;
		/* margin-bottom: 10px; */
	}

	ul li i {
		margin-right: 10px;
		font-size: 10px;
		width: 20px;
		text-align: center;
	}
}
#info-container {
	width: auto;
	height: auto;
	margin: 10px auto;
	overflow-y: auto;
	display: block;
}
.search-result {
	// display: none;
	width: 100%;
	background-color: #f0f0f0;
	border-radius: 8px;
	padding: 30px 20px;
	margin: 10px auto;
	text-align: center;
	opacity: 1;
	box-shadow: 0 0 10px 0 #cccccc;
}
.sales-man {
	display: flex;
	padding: 20px;
	flex-direction: column;
	align-items: flex-start;
	gap: 6px;
	align-self: stretch;
	background-color: #e8f4ff;
	border-radius: 8px;
	box-shadow: 0 0 10px 0 #cccccc;
}
.sales-man--phone,
.sales-man--mail {
	span, a {
		font-size: 14px;
		border-bottom: 1px solid #6e6e6e;
		margin-left: 10px;
        text-decoration: none;
	}
}
.accordions {
	.collapsed {
		display: none;
	}
}
.accordion-header {
	display: flex;
	justify-content: space-between;
	color: #ffffff;
	background-color: #00559d;
	margin-top: 10px;
	padding: 14px 20px;
	border-radius: 8px;
}
.accordion-header span {
	font-size: 16px;
}
.accordion-header-icon {
	color: #ffffff !important;
}
// .highlight {
// 	outline: 2px solid #00559d !important; /* Adjust the shadow as needed */
// 	transition: border 0.5s ease !important;
// }
.accordion-body {
	display: flex;
	flex-direction: column;
	gap: 6px;
	background-color: #ffffff;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 0 10px 0 #cccccc;
	margin-top: 10px;
	a {
		color: #6e6e6e;
		font-weight: 700;
		text-decoration: none;
		border-bottom: 1px solid #00559d;
		margin-left: 10px;
	}
}
.accordion-body h3,
.sales-man h3 {
	font-size: clamp(0.8rem, 3vw, 1rem);
    font-weight: 700;
	text-transform: uppercase;
	// margin-top: 10px;
}
.accordion-icon,
.card-icon {
	font-size: 24px !important;
	color: #00559d;
}
.accordion-item,
.card-item {
	color: #6e6e6e;
	font-size: 14px;
}
.accordion-distance {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-size: 24px;
    span {
        font-size: 16px;
        margin-right: 10px;
    }
}
.accordion-item {
	svg {
		display: inline;
		width: 11px;
		fill: #00559d;
	}
	span {
		margin-left: 10px;
	}
}
.accordion-url {
    a, svg {
        color: #00559d;
    }
}

@media screen and (max-width: 768px) {
    .findDist-container {
        display: flex;
        flex-direction: column-reverse;
    }
    .ais-GeoSearch {
        overflow: hidden;
    }
	.ais-GeoSearch-map {
		width: 100% !important;
		height: 250px !important;
		position: static !important;
	}
	.findDist-modal {
		max-width: 100%;
		margin: 0;
        padding: 20px;
		background-color: #ffffff;
		position: static;
		border-radius: 8px;
		padding-bottom: 20px;
		background-color: #f5f5f5;
	}
	.sales-man--name {
		font-size: 16px;
	}
	.sales-man--phone span,
	.sales-man--mail a {
		font-size: 14px;
	}
	.accordion-header span {
		font-size: 16px;
	}
}

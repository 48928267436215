.aa-Input {
  padding-left: 5px;
}

.aa-SubmitButton {
  border-style: none !important;
  padding-left: 10px !important;
}

.aa-Label{
  margin-bottom: 0;
}

.aa-Panel, .aa-Autocomplete, .aa-DetachedFormContainer{
  z-index: 9999999999 !important;
}

.quickSearchResults{
  all: unset !important;
}

.quickSearchWrap .form-input{
  background: none !important;
  border: none !important;
  padding-left: 0px !important;
}
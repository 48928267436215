:root {
  --my-custom-themecolor: #5468ff;
}
$themeColor: var(--my-custom-themecolor);

.refinement-wrapper{
  position: fixed;
  width: 17vw;
  overflow-y: auto;
  max-height: 780px;
  @media only screen and (max-width: 600px) {
      position: unset;
    }
}

@supports (-webkit-appearance: none) {
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 12px;
  }
}

.ais-DynamicWidgets{
  height: 73vh;
  overflow: auto;
  overflow-x: hidden;
}

.ais-HierarchicalMenu-showMore{
  margin-bottom: 1rem;
}

.ais-HierarchicalMenu-showMore:focus{
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  background: $themeColor !important;
  color: white !important;
}

.unified-refinements {
  &__content {
    &__header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      min-height: 80px;

      &--title {
        color: #21243d;
        font-weight: bold;
      }
      &--clear-filters {
        align-items: center;
        display: flex;
        svg {
          margin-right: 8px;
        }
      }
    }
  }
}

.ais-Panel li {
  padding: 5px 10px;
}


.k-custom-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: flex;
    margin: 0 auto;
    // max-width: 90%;
    padding: 10px 5px;
    margin-top: -25px;
}

.k-custom-select.ais-HitsPerPage-select {
    font-size: 14px;
    border: 1px solid #d6d6e7;
    margin: 10px;
    background-color: #fff;
    background-position: right 1rem center, 0 0;
    background-repeat: no-repeat;
    background-size: 15px, auto;
    box-shadow: rgb(119 122 175 / 30%) 0 1px 4px 0 inset;
    background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' fill='rgba(117, 117, 117, 0.999)' /></svg>");
    min-width: 100px;
}

.w-15 {
    width: 15%!important;
}

.k-custom-hit-per-page-container {
    // width: 90%;
}

.model-pdf {
    background: #1e72ce;
    color: #fff;
    text-transform: uppercase;
    padding: 5px 20px;
    width: 200px;
    border-radius: 3px;
    cursor: pointer;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.model-pdf:hover {
    color: #fff;
    border: #fff;
}

.k-custom-search-box, .k-custom-filter-box {
    margin: auto;
    //margin-right: 0;
}

.k-custom-filter-box { 
    margin-bottom: 10px;
}

.k-custom-thead {
    border-color: #1e72ce;
}

.k-custom-tbody {
    border-top: 1px solid currentColor;
}

.k-custom-searchbox {
    font-size: 14px;
}
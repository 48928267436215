#blog-search-input {
    .osgBlogSearch {
        position: relative;
        .ais-SearchBox {
            width: 100% !important;
            border-radius: 3px;
        }
        .ais-SearchBox-input {
            padding-right: 2rem !important;
            padding-left: 1rem !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }
        .ais-SearchBox-form::before {
            display: none;
            // color: #000000;
            // left: initial !important;
            // right: 1rem !important;
        }
        .ais-SearchBox-reset {
            display: none !important;
        }
    }
    .blog-search-wrapper {
        position: relative;
        .blog-search-icon {
            color: #6E6E6E;
            width: 13px;
            height: 13px;
            background-color: #FFFFFF;
            position: absolute;
            right: 15px;
            left: initial;
            top: 0; 
            bottom: 0; 
            margin: auto 0;
        }
    }
}
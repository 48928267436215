@import "~@fontsource/homenaje/index.css";
.solana-multi-wrapper{
    // width: 20%;
    display: flex;
    flex-direction: column;
    .solana-loader{
        margin-bottom: 0.5rem;
        display: flex;
        display: none;
        justify-content: center;
    }
    .solana-content{
        display: flex;
        justify-content: center;
        flex-direction: column;
        // align-items: center;
        font-family: sans-serif;
    }
    .solana-button-wrapper{
        display: flex;
        gap: 1rem;
        margin-top: 0.5rem;
    }
    p{
        font-size: 1.3rem;
    }


}
.NFT-styles{
    padding: 5px;
    color: white;    
    font-size:small;
}
.NFT-SOLD{    
    background-color: #808080;
}

.solana-success{
    background-color: rgb(29, 151, 29);
}
.solana-error{
    background-color: rgb(183, 57, 57);
}
.solana-normal{
    color: black;
    background-color: white;
    width: 50%;
}
.solana-content-error{
    margin-bottom: 0.5rem;
}
//solana table
.NFT-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2%;
    .NFT-details{
        display: flex;
        gap: 1.5rem;
        margin-bottom: 2rem;
        .NFT-img{
            width: 50%;
            // border: 1px solid black;
            img{
                width: 100%;  
                object-fit: contain;
                border-radius: 4%;
            }
        }
        .NFT-info{
            width: 50%;
            margin-left: 20px;
            .NFT-name{
                width: 458px;
                height: 41px;
                font-family: sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 30px;
                line-height: 41px;
                margin-top: 20px;
                /* identical to box height */
                
                display: flex;
                align-items: center;
                
                color: #000000;
                
            }
            .NFT-btn{
                margin-top: 3rem;
            }
            .NFT-price{
                width: 291px;
                height: 65px;
                font-family: sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 48px;
                line-height: 65px;
                display: flex;
                align-items: center;
                color: #757575;
                margin-top: 20px;

            }
            .NFT-sku{
                width: 291px;
                height: 16px;
                font-family: sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 27px;
                display: flex;
                align-items: center;
                margin-top: 20px;
                margin-bottom: 40px;
                color: #757575;
                p{
                    margin-left: 5px;
                    margin-bottom: 0px !important;
                }
            }
            .NFT-text-Owner{
                font-size:large;
            }
        }
    }
    .NFT-desc{
        display: flex;
        flex-direction: column;
        .NFT-desc-header{
            font-size:x-large;
        }
        .NFT-desc-text{
            font-size:large;
        }
    }
    .sku{
        margin-bottom: 0px !important;
    }
    .NFT-attributes-container{
        margin: 1.5rem 0rem;
        width: 100%;
        font-size: large;
        .NFT-attributes-header{
            margin-bottom: 1rem;
        }
        .NFT-attributes-cards{
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            .NFT-cards{
                width: 25%;
                text-align: center;
                border: 1px solid #512da8;
                background-color: #512da8;
                color: white;
                padding: 0.2rem;
                border-radius: 0.2rem;
            }
        }
    }
    .NFT-details-container{
        width: 100%;
        font-size: large;
        .NFT-details-table{
            // width: 25%;
            .NFT-details-table-data{
                display: flex;
                justify-content: space-between;
                // gap: 5rem;
                .NFT-links{
                    color: #512da8;
                }
            }
        }
    }
}
.send-btn{
    display: none;
}

// h1{
//     font-family: "Homenaje";
//     font-weight: 400;
//     font-size: 80px;
//     line-height: 122%;
//     text-align: center;
// }

.creator{
    display: flex;
    margin-top: 10px;
}

.creator img{
    left: 8.33%;
    right: 4.17%;
    top: 4.17%;
    bottom: 8.33%;
}
.creatorText{
    margin-left: 15px;
    width: 223px;
    height: 16px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #757575;
    align-items: baseline;
    white-space: nowrap;
}
.creatorText p{
    color: #000000;
    margin-left: 5px;
    white-space: nowrap;
}

.addresses{
    height: 16px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #757575;
    align-items: baseline;
    white-space: nowrap;
    display: flex;
    margin-bottom: 10px;
}

.addresses p{
    color: #000000;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 300px;
    margin-left: 5px;
}

 .truncate{
    display: inline-block;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-family: sans-serif;
    font-size: small;
    opacity: 50%;
}

.rwt__tabs[data-rwt-vertical="true"] {
    display: flex;
  }
  
  .rwt__tablist:not([aria-orientation="vertical"]) {
      border-bottom: 1px solid #ddd;
  }
  
  .rwt__tablist[aria-orientation="vertical"] {
    display: flex;
      flex-direction: column;
      flex-shrink: 0;
      flex-grow: 0;
      border-right: 1px solid #ddd;
      margin-right: 1rem;
  }
  
  .rwt__tab {
      background: transparent;
      border: 0;
      font-family: inherit;
      font-size: inherit;
      padding: 1rem 2rem;
    transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  }
  
  .rwt__tab[aria-selected="false"]:hover,
  .rwt__tab:focus {
    outline: 0;
    background-color: #f4f4f4;
      background-color: rgba(0,0,0,0.05);
  }
  
  .rwt__tab[aria-selected="true"] {
      position: relative;
  }
  
  .rwt__tab[aria-selected="true"]:after {
      content: '';
      position: absolute;
  }
  
  .rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
    bottom: -1px;
      left: 0;
      width: 100%;
      border-bottom: 3px solid #000000;
  }
  
  .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
    right: -1px;
    top: 0;
    height: 100%;
    border-right: 3px solid #000000;
  }

.react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: black;
    border-bottom: 3px solid #000000;
}
    .react-tabs__tab {
        display: inline-block;
        bottom: -1px;
        position: relative;
        list-style: none;
        padding: 6px 12px;
        cursor: pointer;
        width: 100px;
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;

        color: #000000;

    }

  .my-tabs{
    margin-top: 100px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    align-items: center;
    color: #000000;
  }

  .my-tablist{
    border-bottom: 1px solid #D3D3D3;
  }

  .divider{
    margin-left: 10px;
  }

  .wrapper{
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    /* or 188% */
    display: flex;
    align-items: center;
    margin-left: 100px;
    margin-top: 20px;
    color: #000000;
  }

  .attributesBox{
    display: flex;
  }

  .attributes{
    align-items: center !important;
    text-align: center !important;
    border: 0.5px solid #757575 !important;
    padding: 20px;
    margin: 10px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    max-width: 500px;
  }
  .attributes .edition{
    /* identical to box height, or 188% */
    text-transform: uppercase;
    color: grey !important;
  }

 .attrValue{
    /* identical to box height, or 188% */
    color: #000000 !important;
  }

  div.accordion{
    border: none !important;
  }

  .NFT-attributes-card1-attr1{
    
  }

  .NFT-attributes-card-:index{
    margin: 2px !important;
    border: 0.5px solid #757575 !important;
  }

  div.NFT-attributes-cards{
    display: flex !important;
  }

  .att-cards{
    margin-right: 50px
  }
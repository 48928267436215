#cart_quick_order_input {
    min-width: 100%;

    .aa-Panel {
        max-width: 400px;
    }

    .aa-Item {
        padding-top: 1rem !important; 
        padding-bottom: 1rem !important;
    }

    @media screen and (min-width: 785px) {
        min-width: 250px;
    }
}

.cartQuickOrderResults {
    &-grid {
        display: grid;
        gap: 0.25rem 0.5rem;
        grid-template-columns: min-content 1fr;
        grid-template-areas: 'image productName' 'image attrs' 'image price';
    }

    &-attrs {
        grid-area: attrs;
        font-size: 0.875rem;

        &__label {
            font-weight: medium;
        }
    }

    &-productName {
        grid-area: productName;

        font-size: 1rem;
        font-weight: bold;
    }

    &-image {
        grid-area: image;

        font-size: 1.25rem;
        font-weight: bold;
    }

    &-price {
        grid-area: price;

        font-weight: bold;
    }

    &-inStock {
        color: #00a651;
    }

    &-noStock {
        color: #aa1f12;
    }

    &-inStock, &-noStock {
        font-size: 1rem;
    }
}

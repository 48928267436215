@use "./styles/header.scss";
@use "./styles/footer.scss";
@use "./styles/hit.scss";
@use "./styles/hits.scss";
@use "./styles/refinements.scss";
@use "./styles/stats.scss";
@use "./styles/hits-header.scss";
@use "./styles/UI/search-icon.scss";
@use "./styles/Autocomplete/ProductItems.scss";
@use "./styles/Autocomplete/Autocomplete.scss";
@use "./styles/Autocomplete/Footer.scss";
@use "./styles/Slider.scss";

// these variables are updated in Unified.jsx
:root {
  --my-custom-themecolor: #5468ff;
  --my-custom-headercolor: #ffffff;
  --my-custom-showFilter: 'flex';
  --my-custom-filterDirection: 'row';
  --my-custom-marginFilterRight: '60px';
  --my-custom-marginFilterLeft: '0px';
  --my-custom-productDescription: 'block';
  --my-custom-displayPrices: 'block';
}

$themeColor: var(--my-custom-themecolor);
$headerColor: var(--my-custom-headercolor);
$showFilter: var(--my-custom-showFilter);
$filterDirection: var(--my-custom-filterDirection);
$marginFilterRight: var(--my-custom-marginFilterRight);
$marginFilterLeft: var(--my-custom-marginFilterLeft);
$productDescription: var(--my-custom-productDescription);
$displayPrices: var(--my-custom-displayPrices);
// these variables are updated in Unified.jsx

// these are items that needs to be fixed
.unified-search-header-container {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  ol,h1, h2, h3, h4, h5, h6{
     margin: 0 !important;
  }
  [href]:focus{
    outline: none !important; 
  }
  p{
    line-height: 18px !important;
  }

  .unified-hits__inner__body{
    &__header--title span{
        font-size: 16px !important;
    }
    &__description span{
      font-size: 14px !important;
    }
    &--currency,&--price{
      font-size: 13px !important;
    }
  }
  .ais-Stats-text,.unified-refinements__content__header--title{
    font-size: 25px !important;
  }
  input:focus,.ais-SearchBox-input:focus{
    border: 2px solid $themeColor !important;
    outline: none !important;
  }
}
// end  these are items that needs to be constant

.unified-container {
  flex-direction: $filterDirection;
  display: flex;
  margin: 0 auto;
  max-width: 90%;
  padding: 10px 5px;

  @media only screen and (max-width: 1000px){
    flex-direction: column;
  }

  .ref-filter-mobile{
    z-index: 5 !important;
    overflow-y: scroll;
    transform: translateX(0);
  }
  .unified-refinements__content__header{
    @media only screen and (max-width: 1000px){
      min-height: 45px;
    }
  }

  &__refinements {
    display: $showFilter;
    flex: 1;
    max-width: 260px;
    margin-right: $marginFilterRight;
    margin-left: $marginFilterLeft;
    @media only screen and (max-width: 1000px){
      display: block;
      max-width: 90%;
      margin-right: 0;
      position: fixed;
      top: 0;
      bottom: 0;
      background-color: white;
      z-index: 1;
      width: 90%;
      right: 0;
      padding: 10px;
      box-shadow: -30px 0 50px 0 rgb(0 0 0 / 40%);
      transform: translateX(120vh);
      transition: transform .3s cubic-bezier(.465,.183,.153,.946);
    }
  }

  &__hits {
    flex: 3;
    .unified-stats-container{
      @media only screen and (max-width: 1000px){
        display:none;
      }
    }
  }
  .close-filter-mobile{
    display: none;
    font-size: 20px;
    flex-direction: row-reverse;
    position: sticky;
    top: 0;
    @media only screen and (max-width: 1000px){
      display: flex;
    }
  }
  .ref-mobile-filter-close-btn{
    border: 1px solid #b6b7d5;
    background-color: #b6b7d5;
    color: black;
    margin-right: 5px;
  }
  .ref-mobile-filter-btn{
    border: 1px solid $themeColor;
    background-color: $themeColor;
    color: white;
    margin-left: 5px;
    .unified-stats-container{
      min-height: 50px;
      justify-content: center;
      .ais-Stats-text{
        color: white;
        font-size: 15px !important;
      }
    }
  }
  .ref-mobile-filter-cnt{
    display: none;
    font-size: 15px;
    margin-top: 20px;
    border-radius: 10px;
    position: sticky;
    bottom: 10px;
    width: 100%;
    justify-content: center;
    @media only screen and (max-width: 1000px){
      display: flex;
    }
    button{
      border-radius: 10px;
      // margin: 0 10px;
      width: 50%;
    }
  }
  &__mobile-filter{
    font-size: 20px;
    width: 50%;
    justify-content: center;
    padding: 5px;
    border: 1px solid $themeColor;
    background-color: $themeColor;
    border-radius: 10px;
    margin: 0 25%;
    color: white;
    position: sticky;
    bottom: 10px;
    display: none;
    .filter-count-container{
      background: hsla(0,0%,100%,.32);
      border-radius: 9999px;
      margin-left: 0.5rem;
      min-width: 22px;
      padding: 2px 6px;
    }
    @media only screen and (max-width: 1000px){
      display: flex;
    }
  }
}
.CurrentRefinements-hidden{
  display:none;
}
.ais-Pagination-list {
  justify-content: center;
}

.ais-Pagination-list:not(.ais-Pagination-item--disabled) a{
  &:hover{
    background: $themeColor !important;
    color:white !important;
    border:none;
  }
}
.ais-Pagination-link--selected{
  background: $themeColor !important;
  color:white !important;
  border:none;
  &:focus{
    background: $themeColor !important;
    color:white !important;
    border:none;
  }
}

.ais-Panel {
  border-top: 1px solid #ebecf3;
  padding-bottom: 1rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  @media only screen and (max-width: 1000px){
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-bottom: 0.5rem;
  }
  &-header{
      font-size: 1rem;
      color: $themeColor;
  }
  .ais-HierarchicalMenu-item{
    &--selected{
      background: $themeColor !important;
      color: white !important;
      border: none !important;
      .ais-HierarchicalMenu-label{
        background: $themeColor !important;
        color: white !important;
        border: none !important;
      }
    }
    &:hover{
      background: $themeColor !important;
      color: white !important;
      border: none !important;
      .ais-HierarchicalMenu-label{
        background: $themeColor !important;
        color: white !important;
        border: none !important;
      }
    }
  }
  .ais-RefinementList-item{
    &--selected{
      background: $themeColor !important;
      color: white !important;
      border: none !important;
      .ais-RefinementList-labelText{
        background: $themeColor !important;
        color: white !important;
        border: none !important;
      }
    }
    &:hover{
      background: $themeColor !important;
      color: white !important;
      border: none !important;
      .ais-RefinementList-labelText{
        background: $themeColor !important;
        color: white !important;
        border: none !important;
      }
    }
  }
}

.ais-ClearRefinements-button {
  color: rgba(33, 36, 61, 0.7);
  box-shadow: none;
  border: none;
  border-radius: 8px;
  background: none;
  cursor: pointer;
  display: flex;
  font: inherit;
  font-size: 0.75rem;
  font-weight: bold;
  justify-content: center;
  padding: 12px;
  text-align: center;
  width: 100%;
  &:focus,
  &:hover,
  &:disabled[disabled] {
    box-shadow: none;
    background-image: none;
  }
}

.ais-ClearRefinements-button--disabled {
  color: rgba(33, 36, 61, 0.5);
}

.ais-HitsPerPage-select {
  appearance: none;
  background: none;
  border: none;
  color: #21243d;
  font-family: inherit;
  box-shadow: none;
  &:focus,
  &:hover,
  &:disabled[disabled] {
    box-shadow: none;
    background-image: none;
  }
}

.aa-Panel {
  max-width: auto;
  width: 100%;
  float: left;
}

.footer-nav-disabled{
  pointer-events: none;
  opacity: 0.6;
}

.algoliaSearchNode{
  width: 100% !important;
}

[contenteditable=true]:focus, [href]:focus, [tabindex]:not([tabindex="-1"]):focus, button:focus, details:focus, input:focus, select:focus, textarea:focus {
    outline: none !important;
}